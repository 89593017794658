import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Heading, Text } from '../../components';
import logo_nametag from '../../assets/images/Website_logo_+_Nametag-removebg-preview.png'


const AboutUs = () => {
  return (

    <div className="flex w-full flex-col items-center gap-[40px] bg-white-A700 md:gap-[74px] sm:gap-[49px]">
        <div className="self-stretch">
          {/* navigation bar section */}
          <Header />

          {/* hero banner section */}
          <div className="flex items-center justify-between gap-5 bg-yellow-50 py-[50px] pl-[120px] md:flex-col md:py-5 md:pl-5">
            <div className="flex w-[46%] flex-col items-center justify-center gap-10 md:w-full">
              <div className="flex   items-center justify-center">
                <img src={logo_nametag} alt="" />
                
              </div>
              
            </div>
          </div>
        </div>

        {/* how it works section */}
       {/* how it works section */}
       
        
          <div id="Section-BarGraph" className="container-xs flex justify-center gap-6 md:flex-col md:p-5">
          

            <div className="flex flex-col gap-[15px] self-stretch">
              
              
            
            <div className="w-full rounded-[20px] bg-red-100 px-[50px] py-[73px] md:p-5">
              <div className="flex flex-col items-start gap-[49px]">
                <div className="flex flex-col gap-[15px] self-stretch">
                <Heading size="6xl" as="h1" className="leading-[140%] tracking-[-0.92px]">
                  <>
                  About Us
                  </>
                </Heading>
                  <Text size="lg" as="p" className="leading-[180%] text-justify">
                  
                  
                  <p>
                    All rights to this website and its contents belong to Dr. Priyanka Dey, Assistant Professor in the Department of Architecture and Regional Planning, Indian Institute of Technology, Kharagpur, West Bengal, India.
                  </p>
                  <br />
                  <p>
                    <strong>Contact us:</strong> grihastimation@gmail.com
                  </p>
                  
                  <div className='flex gap-2'>
                    <p><strong>Address:</strong></p>
                    <div>
                      Dr. Priyanka Dey <br />
                      Assistant Professor,<br />
                      Department of Architecture and Regional Planning,<br />
                      Indian Institute of Technology Kharagpur <br />
                      Kharagpur-721302, Paschim Medinipur,<br />
                      West Bengal, India
                    </div>

                  </div>
                  <br />
                  
                  <h2><strong>Website developed and maintained by:</strong></h2>
                  <br />
                  <ul>
                    <li>Dr. Priyanka Dey</li>
                    <li>Shivam Bisen</li>
                    <li>Parineeta Dwivedi</li>
                    <li>Shalini Keshri</li>
                    <li>Kasturi Mandal</li>
                  </ul>
              </Text>
                </div>
                
              </div>
            </div>
          </div>
            
          
          </div>

          <div className="line"></div>
         
        

        
       
        <Footer />
      </div>
    // <div className="about-us-container">
    //   <h1>About Us</h1>
      
    // </div>
  );
};

export default AboutUs;
