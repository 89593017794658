import MegaMenu1 from "../MegaMenu1";
import { Button, Heading, Img } from "./..";
import logo from "../../assets/images/Website_logo_+_Nametag-removebg-preview.png"
import React from "react";
import { Link } from "react-router-dom";

export default function Header({ ...props }) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [menuOpen1, setMenuOpen1] = React.useState(false);
  const [menuOpen2, setMenuOpen2] = React.useState(false);

  return (
    <header
      {...props}
      className={`${props.className} flex justify-center items-center py-[19px] bg-white-A700 relative`}
    >
      <div className="container-xs flex items-center justify-between gap-5 md:flex-col md:p-5">
        <Link to='/'><Img src={logo} alt="header logo" className="h-[50px] object-contain" />
        </Link>
        
        <ul className="!mb-[7px] flex gap-10 sm:flex-col">
        <li>
            <a href="/user-guide">
              <Heading as="h6">User Guide </Heading>
            </a>
          </li>
          <li>
            
            <a href="/about-grahsatimation">
              <Heading as="h6">About Grihastimation </Heading>
            </a>
          </li>
          <li>
            <a href="/about-us">
              <Heading as="h6">About Us</Heading>
            </a>
          </li>
        </ul>
        
      </div>
    </header>
  );
}
