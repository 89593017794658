import React from 'react'
import G from '../../../assets/GG.png'

const  G_0 = ({requestData}) => {

    const data = requestData.Distribution
  return (
    <div><div className='distribution-container_G'>
    <div className="distribution-left_G">
      <p>Cost of Brick Work from Plinth to Roof : {data.BrickWork.toLocaleString('en-IN')+' INR'}</p>
      <p>Cost of Door and Windows : {data.Door_Window.toLocaleString('en-IN')+' INR'} </p>    
      <p className='Fault_0'>Cost of Foundaion and Plinth : {data.Foundation_plinth.toLocaleString('en-IN')+' INR'}</p>

    </div>
    <div className="distribution-mid_G">
      <img src={G}  alt="" />
    </div>
    <div className="distribution-right_G">
      <p>Cost of RCC Roofing and Terracing : {data.Roofing.toLocaleString('en-IN')+" INR"} </p>

      <p>Cost of RCC except Roofing : {data.RCC.toLocaleString('en-IN')+' INR'}</p>
      <p>Cost of Plastering and Finishing : {data.Plastering.toLocaleString('en-IN')+' INR'}</p>
      <p>Cost of Flooring : {data.Flooring.toLocaleString('en-IN')+' INR'}</p>

    </div>
    

</div></div>
  )
}

export default G_0