import React, { useState, useEffect, useRef } from 'react';
import { Chart, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

Chart.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const StackedBarChart = ({ requestData }) => {

  const data = requestData;
  const chartRef = useRef(null);
  let tooltipfont =18;
  let Sizex =18;
  let Sizey =18;
  let tooltiptitle =18;
  let tooltipfooter =18;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 550);
  const [isTab, setIsTab] = useState(window.innerWidth < 755 && window.innerWidth > 550);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 550);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    const handleResize = () => setIsTab(window.innerWidth < 755 && window.innerWidth > 550);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if(isMobile){
    tooltipfont=5.5;
    Sizex=12;
    Sizey=10;
    tooltiptitle=6;
    tooltipfooter=6;
  }
  if(isTab){
    tooltipfont=11;
    Sizex=10;
    Sizey=9;
    tooltiptitle=12;
    tooltipfooter=12;
  }

  const chartData = {
    labels: ['Material', 'Labour', 'Fitting & Fixture', 'Other Expenses'],
    datasets: [
      {
        label: 'Cement',
        data: [
          data.Material.Cement,
          0,
          0,
          0
        ],
        backgroundColor: 'rgba(255, 99, 132, 0.5)', // Red
      },
      {
        label: 'Sand',
        data: [
          data.Material.Sand,
          0,
          0,
          0
        ],
        backgroundColor: 'rgba(54, 162, 235, 0.5)', // Blue
      },
      {
        label: 'Brick',
        data: [
          data.Material.Brick,
          0,
          0,
          0
        ],
        backgroundColor: 'rgba(255, 206, 86, 0.5)', // Yellow
      },
      {
        label: 'Aggregates 20mm',
        data: [
          data.Material.Aggregate_20mm,
          0,
          0,
          0
        ],
        backgroundColor: 'rgba(75, 192, 192, 0.5)', // Teal
      },
      {
        label: 'Aggregates 40mm',
        data: [
          data.Material.Aggregate_40mm,
          0,
          0,
          0
        ],
        backgroundColor: 'rgba(153, 102, 255, 0.5)', // Purple
      },
      {
        label: 'Steel',
        data: [
          data.Material.Steel,
          0,
          0,
          0
        ],
        backgroundColor: 'rgba(255, 159, 64, 0.5)', // Orange
      },
      {
        label: 'Brick Aggregates',
        data: [
          data.Material.Brick_aggregate,
          0,
          0,
          0
        ],
        backgroundColor: 'rgba(119, 221, 119, 0.5)', // Green
      },
      {
        label: 'Lime',
        data: [
          data.Material.Lime,
          0,
          0,
          0
        ],
        backgroundColor: 'rgba(255, 102, 204, 0.5)', // Pink
      },
      {
        label: 'Timber',
        data: [
          data.Material.Timber,
          0,
          0,
          0
        ],
        backgroundColor: 'rgba(160, 82, 45, 0.5)', // Brown
      },
      {
        label: 'Surkhi',
        data: [
          data.Material.Surkhi,
          0,
          0,
          0
        ],
        backgroundColor: 'rgba(0, 128, 0, 0.5)', // Dark Green
      },
      {
        label: 'Primer',
        data: [
          data.Material.Primer,
          0,
          0,
          0
        ],
        backgroundColor: 'rgba(128, 128, 128, 0.5)', // Gray
      },
      {
        label: 'Paint',
        data: [
          data.Material.Paint,
          0,
          0,
          0
        ],
        backgroundColor: 'rgba(255, 255, 0, 0.5)', // Yellow
      },
      {
        label: 'Mason',
        data: [
          0,
          data.Labour.Mason,
          0,
          0
        ],
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      },
      {
        label: 'Mazdoor',
        data: [
          0,
          data.Labour.Mazdoor,
          0,
          0
        ],
        backgroundColor: 'rgba(153, 102, 255, 0.5)',
      },
      {
        label: 'Painter',
        data: [
          0,
          data.Labour.Painter,
          0,
          0
        ],
        backgroundColor: 'rgba(255, 206, 86, 0.5)',
      },
      {
        label: 'Carpenter',
        data: [
          0,
          data.Labour.Carpenter,
          0,
          0
        ],
        backgroundColor: 'rgba(255, 159, 64, 0.5)',
      },
      {
        label: 'Blacksmith',
        data: [
          0,
          data.Labour.Blacksmith,
          0,
          0
        ],
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Water Supply',
        data: [
          0,
          0,
          data.Utility.Water_supply,
          0
        ],
        backgroundColor: 'rgba(255, 159, 64, 0.5)',
      },
      {
        label: 'Sanitation',
        data: [
          0,
          0,
          data.Utility.Sanitation,
          0
        ],
        backgroundColor: 'rgba(255, 205, 86, 0.5)',
      },
      {
        label: 'Electrification',
        data: [
          0,
          0,
          data.Utility.Electrification,
          0
        ],
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      },
      {
        label: 'Electric fitting',
        data: [
          0,
          0,
          data.Utility.Electric_fitting,
          0
        ],
        backgroundColor: 'rgba(153, 102, 255, 0.5)',
      },
      {
        label: 'Boundary Wall and Street Improvement',
        data: [
          0,
          0,
          data.Utility.Boundary_Wall_Street_and_Site_Improvement,
          0
        ],
        backgroundColor: 'rgba(153, 102, 255, 0.5)',
      },
      {
        label: 'Work Charges',
        data: [
          0,
          0,
          0,
          data.Other_Expanses.Work_Charge
        ],
        backgroundColor: 'rgba(201, 203, 207, 0.5)',
      },
      {
        label: 'Tools and Equipment',
        data: [
          0,
          0,
          0,
          data.Other_Expanses.Tools_and_Equipments
        ],
        backgroundColor: 'rgba(153, 102, 255, 0.5)',
      },
    ]
  };


  const options = {
    plugins: {
      legend: {
        display: false // Disable the legend
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.raw !== null && context.raw !== 0) { // Exclude zero values
              label += new Intl.NumberFormat('en-IN').format(context.raw) + " INR";
            } else {
              label = ''; // If value is zero, set label to empty string
            }
            return label;
          },
          footer: (tooltipItems) => {
            const total = tooltipItems.reduce((sum, item) => sum + item.raw, 0);
            return 'Total: ' + new Intl.NumberFormat('en-IN').format(total) + " INR";
          }
        },
        mode: 'index',
        intersect: false,
        
        bodyFont: {
          size: tooltipfont // Font size for tooltip body
        },
        titleFont: {
          size: tooltiptitle // Font size for tooltip title
        },
        footerFont: {
          size: tooltipfooter // Font size for tooltip footer
        },
        
      },
      title: {
        display: true,
        text: 'Stacked Bar Chart', // Example title
        font: {
          size: 20 // Font size for title
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          font: {
            size: Sizex // Font size for x-axis labels
          }
        }
      },
      y: {
        stacked: true,
        ticks: {
          font: {
            size: Sizey // Font size for y-axis labels
          }
        }
      }
    }
  };
  

  return <Bar data={chartData} options={options} ref={chartRef} />;
};

export default StackedBarChart;
