import React from 'react';
import Piechart from './Piechart';

const MaterialBreakdown = ({ requestData }) => {
  // Check if requestData and requestData.material are defined
  if (!requestData || !requestData.Material) {
    return <div>Loading...</div>; // Render a loading state or handle it appropriately
  }

  const { Material, Labour, Utility, Other_Expanses, Miscellaneous } = requestData;

  const renderTableRows = (data, category) => {
    // Exclude the last key-value pair
    const dataEntries = Object.entries(data).slice(0, -1);
    
    const rows = dataEntries.map(([key, value], index) => (
      <tr key={key}>
        <td>{key.replace(/_/g, ' ')}</td>
        <td>{value.toLocaleString('en-IN')}</td>
      </tr>
    ));
  
    const total = dataEntries.reduce((sum, [_, value]) => sum + value, 0);
  
    rows.push(
      <tr key={`${category}-total`} className="total-row">
        <td colSpan="2">Total</td>
        <td>{total.toLocaleString('en-IN')} INR</td>
      </tr>
    );
  
    return (
      <>
        <tr>
          <td rowSpan={dataEntries.length + 1}>{category}</td>
        </tr>
        {rows}
      </>
    );
  };

  return (
    <div className="cost-breakup">
      <h1>Cost-breakup</h1>
      <div className="Cost-breakup-detailed">
        <table className="cost-table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Item</th>
              <th>Cost (INR)</th>
            </tr>
          </thead>
          <tbody>
            {renderTableRows(Material, 'Materials')}
          </tbody>
        </table>
        <table className="cost-table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Item</th>
              <th>Cost (INR)</th>
            </tr>
          </thead>
          <tbody>
            {renderTableRows(Labour, 'Labour')}
          </tbody>
        </table>
        <table className="cost-table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Item</th>
              <th>Cost (INR)</th>
            </tr>
          </thead>
          <tbody>
            {renderTableRows(Utility, 'Utilities')}
          </tbody>
        </table>
        <table className="cost-table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Item</th>
              <th>Cost (INR)</th>
            </tr>
          </thead>
          <tbody>
            {renderTableRows(Other_Expanses, 'Other Expenses')}
          </tbody>
        </table>
        <table className="cost-table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Item</th>
              <th>Cost (INR)</th>
            </tr>
          </thead>
          <tbody>
            {renderTableRows(Miscellaneous, 'Contingency')}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MaterialBreakdown;
