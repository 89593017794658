import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Heading, Text } from '../../components';
import logo_nametag from '../../assets/images/Website_logo_+_Nametag-removebg-preview.png'




const AboutGrihastimation = () => {
 
  return (

    <div className="flex w-full flex-col items-center gap-[40px] bg-white-A700 md:gap-[74px] sm:gap-[49px]">
        <div className="self-stretch">
          {/* navigation bar section */}
          <Header />

          {/* hero banner section */}
          <div className="flex items-center justify-between gap-5 bg-yellow-50 py-[50px] pl-[120px] md:flex-col md:py-5 md:pl-5">
            <div className="flex w-[46%] flex-col gap-10 md:w-full">
              <div className="flex flex-col gap-[15px]">
              <img src={logo_nametag} alt="" />
                
              </div>
              
            </div>
          </div>
        </div>

        {/* how it works section */}
       {/* how it works section */}
       
        
          <div id="Section-BarGraph" className="container-xs flex justify-center gap-6 md:flex-col md:p-5">
          

            <div className="flex flex-col gap-[15px] self-stretch">
              
              
            
            <div className="w-full rounded-[20px] bg-red-100 px-[50px] py-[73px] md:p-5">
              <div className="flex flex-col items-start gap-[49px]">
                <div className="flex flex-col gap-[15px] self-stretch">
                <Heading size="6xl" as="h1" className="leading-[140%] tracking-[-0.92px]">
                  <>
                  About Grihastimation
                  </>
                </Heading>
                  <Text size="lg" as="p" className="leading-[180%] text-justify">
                  <p>
                        <strong>Grihastimation</strong> is a free online architectural estimator for low-rise residential buildings that assists users in developing an approximate idea about the expenditure and its breakup that can be incurred in their home construction. This tool was developed for Indian users undertaking home construction to give them an educated idea about the expected sub-heads of cost expenditure so that they can plan their expenses accordingly.
                  </p>
                  <br />
                  <p>
                      Most online estimation tools available today provide per square foot cost, while Grihastimation provides an idea about the breakup of expenditure throughout the duration of construction. Grihastimation lets users plan their expenses by giving a clear idea about cost divisions. This tool also considers the number of storeys or floors in the building, in addition to the built-up area and site location, to determine the cost.
                  </p>
                  <br />
                  <p>
                    Grihastimation aims to make the practice of architectural estimation accessible to Indian citizens. It is imperative to plan finances ahead of construction, especially for users exploring affordable housing options or availing financial assistance from banks or government schemes. This tool can be used by homeowners undertaking the construction of their house, professionals from the construction industry, and students or academicians from the field of architecture and civil engineering.
                  </p>
                  <p>
                    This is an approximate estimate of your house considering the built-up area, number of floors, location, standard building plan layout, and specifications. It includes the following aspects:
                  </p>
                  <br />
                  <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li style={{ marginBottom: '5px' }}>Cost of materials.</li>
                        <li style={{ marginBottom: '5px' }}>Cost of labour.</li>
                        <li style={{ marginBottom: '5px' }}>Cost of water supply.</li>
                        <li style={{ marginBottom: '5px' }}>Cost of sanitation.</li>
                        <li style={{ marginBottom: '5px' }}>Cost of electrification and electrical fittings.</li>
                        <li style={{ marginBottom: '5px' }}>Cost of boundary wall, street and site improvement from drawing service lines to site.</li>
                        <li style={{ marginBottom: '5px' }}>Cost of hiring equipment and tools during construction.</li>
                        <li style={{ marginBottom: '5px' }}>Cost of site supervision and contractor’s profits.</li>
                        <li style={{ marginBottom: '5px' }}>Additional miscellaneous unforeseen expenditure if any.</li>
                   </ul>
                  <br />
                  <p>
                    The estimate uses the current year’s government of India certified applicable rates to calculate the cost of materials and labour.
                  </p>
                  <br />
                  <p>
                    Though the cost estimate has considered additional unforeseen expenditure, the actual cost may exceed this estimate due to many reasons, for instance:
                  </p>
                  <br />
                  <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li style={{ marginBottom: '5px' }}>Additional architectural features and facade treatment.</li>
                    <li style={{ marginBottom: '5px' }}>Higher standard of specifications selected for building materials especially for interior finishes.</li>
                    <li style={{ marginBottom: '5px' }}>Use of materials not available locally or requiring long-distance transportation.</li>
                    <li style={{ marginBottom: '5px' }}>Remote location of the site or if the site is not easily accessible from main streets.</li>
                    <li style={{ marginBottom: '5px' }}>Delays in construction.</li>
                    <li style={{ marginBottom: '5px' }}>Changes in rates of building materials due to local market forces.</li>
                  </ul>
                  <br /> 
                  <p>Grihastimation is currently designed to provide cost estimates for only low-rise residential buildings i.e. upto four-storeys. Grihastimation assumes that the single-story ,double-story and triple-story  structures are predominantly load-bearing in nature, while the four-story building is an RCC frame structure with brick infill walls.</p>
              </Text>
                </div>
                
              </div>
            </div>
          </div>
            
          
          </div>

          <div className="line"></div>
         
        

        
       
        <Footer />
      </div>
      
  );
};

export default AboutGrihastimation;
