import { Helmet } from "react-helmet";
import { Button, Img, Heading } from "../../components";
import Header from "../../components/Header";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

export default function ErrorPage() {
  const navigate=useNavigate();
  return (
    <>
      <Helmet>
        <title>Page Not Found - Error 404</title>
        <meta
          name="description"
          content="Oops! Something went wrong. The page you're looking for can't be found. Return to the Homepage or explore our Listings, Agents, and Property Blog for more information."
        />
      </Helmet>
      <div className="flex w-full flex-col items-center justify-center gap-[116px] bg-gray-50_01 pb-[195px] md:gap-[87px] md:pb-5 sm:gap-[58px]">
        {/* header navigation section */}
        <Header className="self-stretch" />

        {/* error content section */}
        <div className="container-xs pl-[347px] pr-[389px] md:p-5 md:px-5">
          <div className="flex flex-col items-center gap-[30px]">
            <Img src="images/img_frame_1000001686.svg" alt="image frame" className="h-[480px] w-full md:h-auto" />
            <div className="flex flex-col items-center gap-[19px]">
              <Heading size="4xl" as="h1" className="tracking-[-0.56px]">
                Something wrong!
              </Heading>
              <Button
                size="4xl"
                variant="outline"
                shape="round"
                color="undefined_undefined"
                rightIcon={<Img src="images/img_arrowright.svg" alt="arrow_right" className="h-[20px] w-[20px]" />}
                className="min-w-[157px] gap-2.5 font-bold"
                onClick={() => navigate("/")}
              >
                Homepage
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
