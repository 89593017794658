import React from 'react'
import G1 from '../../../assets/images/GG 1.png'
import {Img} from '../../../components'

const  G_1 = ({requestData}) => {

    const data = requestData.Distribution
  return (
    <div>
      <div className='distribution-container_G_1'>
          <div className="distribution-left_G_1">
          <p>Cost of RCC Roofing and Terracing : {data.Roofing.toLocaleString('en-IN')+" INR"} </p>
            <p>Cost of Doors and Windows : {data.Door_Window.toLocaleString('en-IN')+' INR'} </p>
            <p>Cost of Brick Work from Plinth to Roof : {data.BrickWork.toLocaleString('en-IN')+' INR'}</p>
            <p>Cost of Foundaion and Plinth : {data.Foundation_plinth.toLocaleString('en-IN')+' INR'}</p>

          </div>
          <div className="distribution-mid_G_1">
          <div style={{ 
              width: 'auto',  // Assuming you want the full width of the image
              height: 'auto', // Height of the cropped portion you want to show
              overflow: 'hidden', 
              position: 'relative' 
            }}>
              <Img 
                src={G1} 
                alt="" 
               
              />
            </div>
          </div>
          <div className="distribution-right_G_1">
            
            <p className='Fault'>Cost of RCC except Roofing : {data.RCC.toLocaleString('en-IN')+' INR'}</p>
            <p>Cost of Plastering and Finishing : {data.Plastering.toLocaleString('en-IN')+' INR'}</p>
            <p>Cost of Flooring : {data.Flooring.toLocaleString('en-IN')+' INR'}</p>

          </div>
    

      </div>
</div>
  )
}

export default G_1