import { Helmet } from "react-helmet";
import { Button, Input, Text, Heading, Img, Slider, CheckBox, SelectBox } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import React, { Suspense,useState,useEffect,useRef } from "react";
import MaterialBreakdown from "./MaterialBreakdown";
import Piechart from "./Piechart";
import Distribution from "./PageComponent/Distribution";
import { useNavigate } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react"


import { TabPanel, TabList, Tab, Tabs } from "react-tabs";
import { number } from "prop-types";
import data from "../../assets/data/city.json"


const dropDownOptions = [
  { label: "Single Storey", value: "Single Storey" },
  { label: "Double Storey", value: "Double Storey" },
  { label: "Triple Storey", value: "Triple Storey" },
  { label: "Four Storey", value: "Four Storey" },

];
const dropDownOptions_2 = [
  { label: "Sqm", value: "sqm" },
  { label: "Sqft", value: "sqft" },

];

export default function LandingPagePage() {

  const [g,setG]=useState('')
  const [g1,setG1]=useState('')
  const [g2,setG2]=useState('')
  const [g3,setG3]=useState('')
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [storey, setStorey] = useState("Single Storey");
  const [unit, setUnit] = useState("sqm");
  const [requestData, setRequestData] = useState(null);
  const costBreakdownRef = useRef(null);

  const navigate = useNavigate();


  const handleGChange = (e) => {
    setG(e.target.value);
   
    
  };
  const handleG1Change = (e) => {
    setG1(e.target.value);
    
  };
  const handleG2Change = (e) => {
    setG2(e.target.value);
    
  };
  const handleG3Change = (e) => {
    setG3(e.target.value);
    
  };
 

  const handleCityChange = (city) => {
    setSelectedCity(city);
  };

  const handleStoreyChange = (storey) => {
    setStorey(storey);
    
  };

  const handleUnitChange = (unit) => {
    setUnit(unit);
   
  };
 

  
  


  useEffect(() => {
    // Fetch city options from the API
    
    // fetch("https://grihastimation-backend.onrender.com/getcity")
    //   .then((response) => response.json())
    //   .then((data) => setCityOptions(data))
    //   .catch((error) => console.error("Error fetching city data:", error));
    setCityOptions(data)
  }, []);
    let area =0
  if(storey.value == "Single Storey"){
     area = parseInt(g) 
  }
  else if(storey.value =="Double Storey"){
    area = parseInt(g) + parseInt(g1) 
  }
  else if(storey.value =="Triple Storey"){
    area = parseInt(g) + parseInt(g1) + parseInt(g2) 
  }
  else if(storey.value =="Four Storey"){
    area = parseInt(g) + parseInt(g1) + parseInt(g2) + parseInt(g3)
  }

 
  const inputData = { city: selectedCity , storey: storey.value, area: area, unit: unit.value}

  const handleSubmit = () => {
    setIsLoading(true)
    
    fetch("https://grihastimation-backend-1.onrender.com/getprice", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(inputData),
    })
      .then((response) => response.json())
      .then((data) => {
        
        setRequestData(data);
        setIsLoading(false)
        if (costBreakdownRef.current) {
          costBreakdownRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false)
      });
  };
  
 
  return (
    <>
      <Helmet>
        <title>Grihastimation</title>
        <meta
          name="description"
          content="Discover your perfect home with our extensive property listings. Whether you're looking to buy, sell, or rent, find the best deals on residential, commercial, and industrial properties. Start your search and enjoy a simple, secure real estate experience."
        />
      </Helmet>
      <div className="flex w-full flex-col items-center gap-[40px] bg-white-A700 md:gap-[74px] sm:gap-[49px]">
        <div className="self-stretch">
          {/* navigation bar section */}
          <Header />

          {/* hero banner section */}
          <div className="flex items-center justify-between gap-5 bg-yellow-50 py-[50px] pl-[120px] md:flex-col md:py-5 md:pl-5">
            <div className="flex w-[46%] flex-col gap-10 md:w-full">
              <div className="flex flex-col gap-[15px]">
                <Heading size="6xl" as="h1" className="leading-[140%] tracking-[-0.92px]">
                  <>
                    Discover Your Dream Home's Cost-
                    <br />
                    Get an Instant Estimate Now
                  </>
                </Heading>
                <Text size="lg" as="p" className="leading-[180%]">
                We help you estimate building costs for your dream home, offering approximate calculations and insights for your construction projects.
                </Text>
              </div>
              <div className="rounded-[16px] bg-white-A700 p-6 sm:p-5">
                <Tabs
                  className="flex flex-col gap-[38px]"
                  selectedTabClassName="!text-white-A700 bg-gray-900 rounded-[4px] padding-[20px]"
                  selectedTabPanelClassName="relative tab-panel--selected"
                >
                  <TabList className="flex flex-wrap justify-between gap-[155px] pb-[9px] pl-[1px] pr-[67px] pt-[3px] md:gap-5 md:px-5">
                    
                    <Tab className="self-start text-lg font-bold text-gray-900">Enter Details</Tab>
                   
                  </TabList>
                  {[...Array(3)].map((_, index) => (
                    <TabPanel key={`tab-panel${index}`} className="absolute items-center">
                      <div className="w-full">
                        <div className="flex flex-col gap-6">
                          <div className="flex flex-col gap-5">
                            <Input
                              shape="round"
                              name="City Input"
                              placeholder={`City`}
                              suffix={
                                <Img src="images/img_linkedin.svg" alt="linkedin" className="h-[20px] w-[20px]" />
                              }
                              className="gap-[35px] border border-solid border-blue_gray-100_01"
                              cityOptions={cityOptions}
                              onChange={handleCityChange}
                            />

                            <SelectBox
                              shape="round"
                              indicator={
                                <Img src="images/img_arrowup.svg" alt="arrow_up" className="h-[20px] w-[20px]" />
                              }
                              name="Price Dropdown"
                              placeholder={`Storey`}
                              options={dropDownOptions}
                              style={{border:"1px solid grey", padding:'2%', fontSize:'20px', borderRadius:'10px'}}
                              className="gap-px border border-solid border-blue_gray-100_01 font-semibold !text-gray-600_02 sm:pr-5"
                              onChange={handleStoreyChange}
                            />
                           {storey.value !=="Single Storey" && storey.value !=="Double Storey" && storey.value !=="Triple Storey" && storey.value !=="Four Storey" && (
                             <div className="flex justify-between">
                                <input
                                  shape="round"
                                  type="number"
                                  name="Type Input"
                                  placeholder="Area"
                                  value={g}
                                  onChange={handleGChange}
                                  style={{border:"1px solid grey", padding:'1.5%', fontSize:'20px', borderRadius:'10px'}}
                                  className="w-2/3 mr-2"
                                />
                                <SelectBox
                                  shape="round"
                                  indicator={
                                    <Img src="images/img_arrowup.svg" alt="arrow_up" className="h-[20px] w-[20px]" />
                                  }
                                  name="Price Dropdown"
                                  placeholder="Unit"

                                  options={dropDownOptions_2}
                                  className="border border-solid border-blue_gray-100_01 font-semibold !text-gray-600_02 sm:pr-5 w-1/3 ml-2"
                                  onChange={handleUnitChange} 
                                />
                             </div>) } 

                             {storey.value =="Single Storey" &&(
                             <div className="flex justify-between">
                                <input
                                  shape="round"
                                  type="number"
                                  name="Type Input"
                                  placeholder="Ground Floor Area"
                                  value={g}
                                  onChange={handleGChange}
                                  style={{border:"1px solid grey", padding:'1.5%', fontSize:'20px', borderRadius:'10px'}}
                                  className="w-2/3 mr-2"
                                />
                                <SelectBox
                                  shape="round"
                                  indicator={
                                    <Img src="images/img_arrowup.svg" alt="arrow_up" className="h-[20px] w-[20px]" />
                                  }
                                  name="Price Dropdown"
                                  placeholder="Unit"

                                  options={dropDownOptions_2}
                                  className="border border-solid border-blue_gray-100_01 font-semibold !text-gray-600_02 sm:pr-5 w-1/3 ml-2"
                                  onChange={handleUnitChange} 
                                />
                             </div>) }
        
                             {storey.value == "Double Storey" && (
                              <div className="input-container">
                                
                                <input
                                        shape="round"
                                        type="number"
                                        name="Type Input"
                                        placeholder="Ground Floor Area"
                                        value={g}
                                        onChange={handleGChange}
                                        style={{border:"1px solid rgb(214 214 214)", height:'60px',padding:'1.5%', fontSize:'20px', borderRadius:'10px'}}
                                        className=" "
                                      />
                                  <input
                                        shape="round"
                                        type="number"
                                        name="Type Input"
                                        placeholder="First Floor Area"
                                        value={g1}
                                        onChange={handleG1Change}
                                        style={{border:"1px solid rgb(214 214 214)", height:'60px',padding:'1.5%', fontSize:'20px', borderRadius:'10px'}}
                                        className=" "
                                      />
                               
                                  <SelectBox
                                    shape="round"
                                    indicator={
                                      <Img src="images/img_arrowup.svg" alt="arrow_up" className="h-[20px] w-[20px]" />
                                    }
                                    name="Price Dropdown"
                                    placeholder="Unit"

                                    options={dropDownOptions_2}
                                    className="border border-solid border-blue_gray-100_01 font-semibold !text-gray-600_02 sm:pr-5 min-w-[200px]"
                                    onChange={handleUnitChange} 
                                  />
                              </div>
                             )}

                            {storey.value == "Triple Storey" && (
                              <div className=" display flex flex-col gap-5">
                                  <div className=" input-container">
                                    
                                      <input
                                        shape="round"
                                        type="number"
                                        name="Type Input"
                                        placeholder="Ground Floor Area"
                                        value={g}
                                        onChange={handleGChange}
                                        style={{border:"1px solid rgb(214 214 214)", height:'60px',padding:'1.5%', fontSize:'20px', borderRadius:'10px'}}
                                        className=" "
                                      />
                                      <input
                                        shape="round"
                                        type="number"
                                        name="Type Input"
                                        placeholder="First Floor Area"
                                        value={g1}
                                        onChange={handleG1Change}
                                        style={{border:"1px solid rgb(214 214 214)", height:'60px',padding:'1.5%', fontSize:'20px', borderRadius:'10px'}}
                                        className=" "
                                      />
                                      <input
                                        shape="round"
                                        type="number"
                                        name="Type Input"
                                        placeholder="Second Floor Area"
                                        value={g2}
                                        onChange={handleG2Change}
                                        style={{border:"1px solid rgb(214 214 214)", height:'60px',padding:'1.5%', fontSize:'20px', borderRadius:'10px'}}
                                        className=" input "
                                      />
                                  </div>
                                      <SelectBox
                                        shape="round"
                                        indicator={
                                          <Img src="images/img_arrowup.svg" alt="arrow_up" className="h-[20px] w-[20px]" />
                                        }
                                        name="Price Dropdown"
                                        placeholder="Unit"

                                        options={dropDownOptions_2}
                                        className="border border-solid border-blue_gray-100_01 font-semibold !text-gray-600_02 sm:pr-5  "
                                        onChange={handleUnitChange} 
                                      />
                                </div>
                             )}

                            {storey.value == "Four Storey" && (
                              <div className=" display flex flex-col gap-5">
                                  <div className=" input-container">
                                    
                                      <input
                                        shape="round"
                                        type="number"
                                        name="Type Input"
                                        placeholder="Ground Floor Area"
                                        value={g}
                                        onChange={handleGChange}
                                        style={{border:"1px solid rgb(214 214 214)", height:'60px',padding:'1.5%', fontSize:'20px', borderRadius:'10px'}}
                                        className=" "
                                      />
                                      <input
                                        shape="round"
                                        type="number"
                                        name="Type Input"
                                        placeholder="First Floor Area"
                                        value={g1}
                                        onChange={handleG1Change}
                                        style={{border:"1px solid rgb(214 214 214)", height:'60px',padding:'1.5%', fontSize:'20px', borderRadius:'10px'}}
                                        className=" "
                                      />
                                      <input
                                        shape="round"
                                        type="number"
                                        name="Type Input"
                                        placeholder="Second Floor Area"
                                        value={g2}
                                        onChange={handleG2Change}
                                        style={{border:"1px solid rgb(214 214 214)", height:'60px',padding:'1.5%', fontSize:'20px', borderRadius:'10px'}}
                                        className=" input "
                                      />
                                      <input
                                        shape="round"
                                        type="number"
                                        name="Type Input"
                                        placeholder="Third Floor Area"
                                        value={g3}
                                        onChange={handleG3Change}
                                        style={{border:"1px solid rgb(214 214 214)", height:'60px',padding:'1.5%', fontSize:'20px', borderRadius:'10px'}}
                                        className=" input "
                                      />
                                  </div>
                                      <SelectBox
                                        shape="round"
                                        indicator={
                                          <Img src="images/img_arrowup.svg" alt="arrow_up" className="h-[20px] w-[20px]" />
                                        }
                                        name="Price Dropdown"
                                        placeholder="Unit"

                                        options={dropDownOptions_2}
                                        className="border border-solid border-blue_gray-100_01 font-semibold !text-gray-600_02 sm:pr-5  "
                                        onChange={handleUnitChange} 
                                      />
                                </div>
                             )}

                          </div>
                          <Button
                            size="4xl"
                            shape="round"
                            className="w-full font-bold sm:px-5 bg-orange-100 hover:bg-orange-300 active:bg-orange-400 transition duration-300 ease-in-out transform focus:outline-none"
                            onClick={handleSubmit}
                          >
                            {isLoading? "Loading ..." :"Enter"}
                          </Button>



                        </div>
                      </div>
                    </TabPanel>
                  ))}
                </Tabs>
              </div>
            </div>
            <Img src="images/img_image.png" alt="main image" className="h-[700px] w-[46%] object-cover md:w-full hero-img" />
          </div>
        </div>

        {/* how it works section */}
       {/* how it works section */}
       
        
          <div id="Section-BarGraph" ref={costBreakdownRef} className="container-xs flex justify-center gap-6 md:flex-col md:p-5">
          {requestData && (

            <div className="flex flex-col gap-[15px] self-stretch">
              <Heading size="5xl" as="h2" className="leading-[140%] tracking-[-0.72px]">
                Cost Breakdown
              </Heading>
              <Text size="lg" as="p" className="leading-[180%]">
                The cost of construction is broken down into various categories. Here is a detailed breakdown of the cost of different materials,labour,utilities,etc.
              </Text>
            
            <div className="w-full rounded-[20px] bg-red-100 px-[50px] py-[73px] md:p-6 ">
              <div className="flex flex-col items-start gap-[49px]">
                <div className="flex flex-col gap-[15px] self-stretch">
                  <Heading size="5xl" as="h2" className="leading-[140%] tracking-[-0.72px]">
                    
                    Total = {requestData.total.toLocaleString('en-IN')} INR
                  </Heading>
                </div>
                {requestData && <Piechart requestData={requestData} className='piechart'/>}
              </div>
            </div>
          </div>
            
          )}
          </div>
          
          {requestData && <MaterialBreakdown requestData={requestData} />}
          
        

        
       
        {/* latest news section */}
        {requestData &&(
        <div className="flex justify-center self-stretch bg-gray-800 py-[120px] md:py-5">
          <div className="container-xs flex flex-col gap-[118px] md:gap-[88px] md:p-5 sm:gap-[59px]">
            <div className="flex flex-col gap-[60px] sm:gap-[30px]">
              <div className="flex items-center justify-between gap-5">
                <Heading size="5xl" as="h2" className="tracking-[-0.72px] !text-white-A700">
                  Cost of Building Components -
                </Heading>
                </div>
               <div className="distribution">
                 <Distribution storey={inputData.storey} requestData={requestData} />
               </div>
              
              
            </div>
            
          </div>
        </div>
        )}
        <div className="line"></div>
        <Analytics />
        {/* footer section */}
        <Footer />
      </div>
    </>
  );
}
