import React, { useState, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";

const shapes = {
  round: "rounded-[10px]",
};
const variants = {
  fill: {
    gray_50_02: "bg-gray-50_02 text-gray-700",
    white_A700: "bg-white-A700 text-gray-600_02",
  },
};
const sizes = {
  xs: "h-[48px] pl-4 pr-[35px] text-sm",
  sm: "h-[60px] px-4 text-lg",
};

const Input = forwardRef(
  (
    {
      className = "",
      name = "",
      placeholder = "",
      type = "text",
      label = "",
      prefix,
      suffix,
      onChange,
      value,
      shape,
      variant = "fill",
      size = "sm",
      color = "",
      cityOptions = [],
      ...restProps
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState(value || "");
    const [filteredOptions, setFilteredOptions] = useState([]);

    useEffect(() => {
      if (inputValue) {
        const options = cityOptions.filter((city) =>
          city.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredOptions(options.length > 0 ? options : ["Others"]);
      } else {
        setFilteredOptions([]);
      }
    }, [inputValue, cityOptions]);

    const handleChange = (e) => {
      setInputValue(e.target.value);
      if (onChange) onChange(e.target.value);
    };

    const handleOptionClick = (option) => {
      setInputValue(option);
      setFilteredOptions([]); // Clear the suggestions
      if (onChange) onChange(option);
    };

    return (
      <div className="relative">
        <label
          className={`${className} flex items-center justify-center cursor-text font-semibold rounded-[10px] ${(shape && shapes[shape]) || ""} ${variants[variant]?.[color] || variants[variant] || ""} ${sizes[size] || ""}`}
        >
          {!!label && label}
          {!!prefix && prefix}
          <input
            ref={ref}
            type={type}
            name={name}
            value={inputValue}
            onChange={handleChange}
            placeholder={placeholder}
            {...restProps}
          />
          {!!suffix && suffix}
        </label>
        {filteredOptions.length > 0 && (
          <ul className="absolute z-10 w-full bg-orange-100 border border-gray-300 rounded-[10px] mt-1 max-h-60 overflow-auto">
            {filteredOptions.map((option, index) => (
              <li
                key={index}
                className="cursor-pointer p-2 hover:bg-orange-300"
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
);

Input.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  shape: PropTypes.oneOf(["round"]),
  size: PropTypes.oneOf(["xs", "sm"]),
  variant: PropTypes.oneOf(["fill"]),
  color: PropTypes.oneOf(["gray_50_02", "white_A700"]),
  cityOptions: PropTypes.arrayOf(PropTypes.string),
};

export { Input };
