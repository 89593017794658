import PropTypes from 'prop-types';

import G from './G';
import G_1 from './G_1'
import G_2 from './G_2'
import G_3 from './G_3'


const Distribution = ({ storey, requestData }) => {
  if (storey === 'Single Storey') {
    return <G requestData={requestData} />;
  }

  if (storey === 'Double Storey') {
    return <G_1 requestData={requestData} />;
  }

  if (storey === 'Triple Storey') {
    return <G_2 requestData={requestData} />;
  }

  if (storey === 'Four Storey') {
    return <G_3 requestData={requestData} />;
  }

  return null;
};

Distribution.propTypes = {
  storey: PropTypes.string.isRequired,
  requestData: PropTypes.object.isRequired,
};

export default Distribution;
