import { Heading, Text, Img } from "./..";
import React from "react";
import logo from '../../assets/images/logo 1.png';
import nametag from '../../assets/images/logo2.png';

export default function Footer({ ...props }) {
  return (
    <footer
      {...props}
      className={`${props.className} flex self-stretch justify-center items-center pt-20 pb-[74px] md:py-5 bg-white-A700`}
    >
      <div className="container-xs flex justify-center md:p-5">
        <div className="flex w-[97%] flex-col md:w-full">
          <div className="flex items-start gap-2.5">
            <Img src={logo} alt="home icon" className="h-[50px] w-[50px]" />
            <Img src={nametag} alt="home icon" className="h-[40px] w-[130px]" />
            
          </div>
          <div className="relative mt-[-13px] flex items-start justify-between gap-5 pr-[589px] md:pr-5 sm:flex-col">
            <ul className="!mt-14 flex flex-col">
              <li>
                <a href="#">
                <Heading as="h6">Dr.Priyanka Dey <br />Assistant Professor,<br />Department of Architecture and Regional Planning,<br /> Indian Institute of Technology Kharagpur <br />Kharagpur-721302, Paschim Medinipur,<br /> West Bengal, India</Heading>                </a>
              </li>
              <li>
                <a href="#" className="mt-[52px]">
                <Heading as="h6">grihastimation@gmail.com</Heading>
                </a>
              </li>
              
              <li>
                <a href="#" className="mt-[174px]">
                  <Heading as="h6">© 2024. All rights reserved.</Heading>
                </a>
              </li>
            </ul>
            
          </div>
        </div>
      </div>
    </footer>
  );
}
