import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Heading, Text } from '../../components';
import Fig1 from '../../assets/images/Details/1.jpg'
import Fig2 from '../../assets/images/Details/2.jpg'
import Fig3 from '../../assets/images/Details/3.jpg'
import Fig3_1 from '../../assets/images/Details/3.1-removebg-preview.png'
import Fig3_2 from '../../assets/images/Details/3.2-removebg-preview.png'
import Fig3_3 from '../../assets/images/Details/3.3-removebg-preview.png'
import Fig3_4 from '../../assets/images/Details/3.4-removebg-preview.png'
import Fig4 from '../../assets/images/Details/4.jpg'
import Fig5 from '../../assets/images/Details/5.jpg'
import Fig6 from '../../assets/images/Details/6.jpg'
import Fig7 from '../../assets/images/Details/7.jpg'
import Fig8 from '../../assets/images/Details/8.jpg'
import logo_nametag from '../../assets/images/Website_logo_+_Nametag-removebg-preview.png'



const UserGuide = () => {
  return (
    <div className="flex w-full flex-col items-center gap-[40px] bg-white-A700 md:gap-[74px] sm:gap-[49px]">
    <div className="self-stretch">
      {/* navigation bar section */}
      <Header />

      {/* hero banner section */}
      <div className="flex items-center justify-between gap-5 bg-yellow-50 py-[50px] pl-[120px] md:flex-col md:py-5 md:pl-5">
        <div className="flex w-[46%] flex-col gap-10 md:w-full">
          <div className="flex flex-col gap-[15px]">
          <img src={logo_nametag} alt="" />
            
          </div>
          
        </div>
      </div>
    </div>

    {/* how it works section */}
   {/* how it works section */}
   
    
      <div id="Section-BarGraph" className="container-xs flex justify-center gap-6 md:flex-col md:p-5">
      

        <div className="flex flex-col gap-[15px] self-stretch">
          
          
        
        <div className="w-full rounded-[20px] bg-red-100 px-[50px] py-[73px] md:p-5">
          <div className="flex flex-col items-start gap-[49px]">

            <Heading size="6xl" as="h1" className="leading-[140%] tracking-[-0.92px]">
              <>
              User Guide
              </>
            </Heading>
            <div className="flex flex-col gap-[15px] self-stretch">
              <Text size="lg" as="p" className="leading-[180%] text-justify flex flex-col justify-center items-center gap-3 ">
                <div className="">
              <p style={{fontSize:'24px'}} className='mb-4'><strong>Details about Inputs:</strong></p>
              <p>
                <strong>1. How to select your city?</strong><br />
                Answer: Please input the city you are building your house in. Grihastimation provides the estimated cost based on the local rates of materials and labour applicable. In case, you cannot find your city in the drop-down menu, please select the nearest city. If you don't find that suitable, select the 'other' option from the drop-down menu. This option will provide an average cost estimate considering prevalent rates in India.
              </p></div>
              <img src={Fig1} alt="" style={{width:'80%',height:'auto'}} />
              <figure>Figure 1: Sample image showing how to enter city name.</figure>
              

          </Text>
            </div>
            
          </div>
        </div>

        <div className="w-full rounded-[20px] bg-red-100 px-[50px] py-[73px] md:p-5">
          <div className="flex flex-col items-start gap-[49px]">
            <div className="flex flex-col gap-[15px] self-stretch">
              <Text size="lg" as="p" className="leading-[180%] text-justify flex flex-col justify-center items-center gap-3 ">
              
             
              <p>
                <strong>2. How to select the number of storeys?</strong><br />
                Answer: This input refers to the number of floors in your house. Please see the figures below and select the one applicable to your house. Select ‘Single storey’ if your building has only 1 floor; ‘Double storey’ if it has 2 floors; ‘Triple storey’ if it has 3 floors and ‘Four storey’ building if it has 4 floors. Refer image below to understand the number of floors.
              </p>
              <img src={Fig2} alt="" style={{width:'80%',height:'auto'}} />
              <figure>Figure 2: Sample image showing how to select the number of storeys.</figure>
              
              <ul className='flex justify-between items-baseline gap-6'>
                
                <li><img src={Fig3_1} alt="" style={{width:'200px',height:'auto'}} />Single storey (1 floor)</li>
                <li><img src={Fig3_2} alt="" style={{width:'80%',height:'auto'}} />Double storey (2 floors)</li>
                <li><img src={Fig3_3} alt="" style={{width:'80%',height:'auto'}} />Triple storey (3 floors)</li>
                <li><img src={Fig3_4} alt="" style={{width:'80%',height:'auto'}} />Four storey (4 floors)</li>
              </ul>
              
              <figure>Figure 3: Sample representing the number of storeys.</figure>
              

          </Text>
            </div>
            
          </div>
        </div>

        <div className="w-full rounded-[20px] bg-red-100 px-[50px] py-[73px] md:p-5">
          <div className="flex flex-col items-start gap-[49px]">
            <div className="flex flex-col gap-[15px] self-stretch">
              <Text size="lg" as="p" className="leading-[180%] text-justify flex flex-col justify-center items-center gap-3 ">
              
              <p>
                <strong>3. How to calculate the area?</strong><br />
                Answer: Grihastimation measures the estimated cost of your house using the total built-up area. By total built-up area, we mean the area that is built on each floor combined. If you already have a plan or a building layout developed, please measure the total area of each floor and add them. Hence you need to calculate the area using the periphery of the building. In case you have a large courtyard within the layout, you may subtract that area from the total. If different floors have different shapes or layouts, calculate the area for each floor separately and then add. If all floors have the same layout you may calculate for the ground floor and simply multiply with the number of floors.
              </p>
              <p>
                If you don't have the plan or layout prepared and are simply starting off with a plot of land, please decide a basic part of the plot you want to build on. Leave aside the setback area of the plot. The setback means the minimum gap that has to be left between the boundary of the plot and the building periphery. Please check the local municipal corporation building byelaws to determine what is the minimum setback to be left for the back, front, and both sides. The rest of the area left within the plot is where you may build. You can decide on the basic shape or outline of the building, calculate that area and multiply with the number of floors to get the total built-up area. Please note that the maximum built-up area that your building can have is also restricted by Floor Area Ratio (FAR) or Floor Space Index (FSI) determined by Municipal Building Byelaws. Check that to ensure that you are not exceeding the area restriction of your locality.
              </p>
              <img src={Fig3} alt="" style={{width:'80%',height:'auto'}} />

              <figure>Figure 4: Sample image showing how to input the total built-up area for your house.</figure>
              
          </Text>
            </div>
            
          </div>
        </div>

        <div className="w-full rounded-[20px] bg-red-100 px-[50px] py-[73px] md:p-5">
          <div className="flex flex-col items-start gap-[49px]">
            <div className="flex flex-col gap-[15px] self-stretch">
              <Text size="lg" as="p" className="leading-[180%] text-justify flex flex-col justify-center items-center gap-3 ">
              <p>
                <strong>4. How to select the unit?</strong><br />
                Answer: Before giving the area input, please select the unit in which you are providing the area i.e. square meters (sqm) or Square Feet (sq ft).
              </p>
              <img src={Fig4} alt="" style={{width:'80%',height:'auto'}} />

              <figure>Figure 5: Sample image showing how to select the unit for area.</figure>
              

          </Text>
            </div>
            
          </div>
        </div>
        <div className="w-full rounded-[20px] bg-red-100 px-[50px] py-[73px] md:p-5">
          <div className="flex flex-col items-start gap-[49px]">
            <div className="flex flex-col gap-[15px] self-stretch">
            <p style={{fontSize:'24px', color:'grey'}} className='mb-4'><strong>Details about Outputs:</strong></p>
             <Text size="lg" as="p" className="leading-[180%] text-justify flex flex-col justify-center items-center gap-3 ">
              
              
              
              <p>
                1. The total estimated cost of your house in INR will appear.<br />
                2. The bar chart (as shown in the figure below) will show the breakup of total cost into the cost of materials, cost of labour, cost of services and utilities, cost of tools and equipment, cost of boundary wall, street improvement (in case the street is dug to draw service lines to plot or site, it needs to be remade) and cost of site preparation (the plot land has to be levelled and prepared pre and post construction). This component also includes the cost of work charges which, comprises the charges taken by the site supervisor who oversees the construction process and the contractor's profits.
              </p>
              <img src={Fig5} alt="" style={{width:'80%',height:'auto'}} />

              <figure>Figure 6: Sample image showing total estimated cost and graphical representation of cost breakdown.</figure>
              <p>
                If you hover your cursor on each bar, it will further show the breakup of each cost component. For example, the cost of materials is further subdivided into the cost of various types of materials used. The cost of labour is divided into the wages associated with each type of labour.
              </p>
              <img src={Fig6} alt="" style={{width:'80%',height:'auto'}} />

              <figure>Figure 7: Sample image showing details of cost breakdown under each category.</figure>
              <p>
                This will be followed by a table with a detailed cost breakup of each cost component and items will be listed with their corresponding associated costs in INR.
              </p>
              <img src={Fig7} alt="" style={{width:'80%',height:'auto'}} />

              <figure>Figure 8: Sample image showing details of the cost breakdown under each category.</figure>
              <p>
                 3. A figure similar to the one shown below will show the cost incurred in making the different parts of the building excluding utilities and services i.e. foundation and plinth, walls, doors and windows, flooring, roofing including RCC roof slab and terracing, Reinforced Concrete works other than roof slab i.e. lintel band and chajja, columns, beams etc, plastering and painting and other finishing.
              </p>
              <img src={Fig8} alt="" style={{width:'80%',height:'auto'}} />

              <figure>Figure 9: Sample image showing the details of the costs incurred in making different building components.</figure>
              <p>
                <strong>Note:</strong> Grihastimation calculates the estimated cost of the building, including cost of materials, cost of labour for building both the foundation and superstructure. It also includes the approximate cost of water supply, sanitation, electrification and fixtures. The cost of tools and equipment needed, contractor's profits, supervisor fees, site and street improvement costs, and fencing or boundary wall costs are included. Finally, a provision is made for contingencies or miscellaneous expenditures if any.
              </p>
              <p>
                To calculate the cost, Grihastimation assumes that your house is a conventional 'pucca' house with brick walls, RCC roof slab, timber doors and windows and conventionally available specifications of materials. Currently, Grihastimation is designed to provide estimates for low-rise residential buildings only, i.e. up to 4-storeyed houses.
              </p> 
              
          </Text>
            </div>
            
          </div>
        </div>

      </div>
        
      
      </div>

      <div className="line"></div>
     
    

    
   
    <Footer />
  </div>
  );
};

export default UserGuide;
