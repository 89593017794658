import React from 'react'
import G3 from '../../../assets/GG 3.png'
import {Img} from '../../../components'

const  G_3 = ({requestData}) => {

    const data = requestData.Distribution
  return (
    <div><div className='distribution-container_G_3'>
    <div className="distribution-left_G_3">
      <p>Cost of Door and Windows : {data.Door_Window.toLocaleString('en-IN')+' INR'} </p>  
      <p>Cost of RCC Roofing and Terracing : {data.Roofing.toLocaleString('en-IN')+" INR"} </p>
      
      <p>Cost of Brick Work from Plinth to Roof : {data.BrickWork.toLocaleString('en-IN')+' INR'}</p>
      <p>Cost of Foundaion and Plinth : {data.Foundation_plinth.toLocaleString('en-IN')+' INR'}</p>

    </div>
    <div className="distribution-mid_G_3">
    <div style={{ 
              width: 'auto',  // Assuming you want the full width of the image
              height: 'auto', // Height of the cropped portion you want to show
              overflow: 'hidden', 
              position: 'relative' 
            }}>
              <Img 
                src={G3} 
                alt="" 
               
              />
            </div>    </div>
    <div className="distribution-right_G_3">
      
      <p className='Fault_3'>Cost of RCC except Roofing : {data.RCC.toLocaleString('en-IN')+' INR'}</p>
      <p>Cost of Plastering and Finishing : {data.Plastering.toLocaleString('en-IN')+' INR'}</p>
      <p>Cost of Flooring : {data.Flooring.toLocaleString('en-IN')+' INR'}</p>

    </div>
    

</div></div>
  )
}

export default G_3