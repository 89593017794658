import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import LandingPage from "pages/LandingPage";
import Error from "pages/Error";
import AboutGrihastimation from "pages/AboutPage/AboutGrahastimation";
import AboutUs from "pages/AboutPage/AboutUs";
import UserGuide from "pages/AboutPage/UserGuid";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "dhiwise-dashboard", element: <Home /> },
    { path: "*", element: <Error /> },
    {
      path: "/",
      element: <LandingPage />,
    },
    {
      path: "error",
      element: <Error />,
    },
    {
      path:"/about-grahsatimation",
      element: <AboutGrihastimation />
    },
    {
      path:"/about-us",
      element: <AboutUs />
    },
    {
      path:"/user-guide",
      element: <UserGuide />
    },
  ]);

  return element;
};

export default ProjectRoutes;
