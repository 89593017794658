import React from "react";
import PropTypes from "prop-types";

const shapes = {
  circle: "rounded-[50%]",
  square: "rounded-[0px]",
  round: "rounded-[10px]",
};
const variants = {
  outline: {
    gray_600_02: "border-gray-600_02 border border-solid text-gray-900",
  },
  fill: {
    white_A700: "bg-white-A700 shadow-xs",
    gray_900: "bg-gray-900 text-white-A700",
  },
};
const sizes = {
  "3xl": "h-[60px] px-3.5",
  xs: "h-[27px] text-lg",
  "4xl": "h-[60px] px-[35px] text-lg",
  lg: "h-[42px] px-6 text-base",
  xl: "h-[48px] px-6 text-base",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "fill",
  size = "xl",
  color = "",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex flex-row items-center justify-center text-center cursor-pointer ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["circle", "square", "round"]),
  size: PropTypes.oneOf(["3xl", "xs", "4xl", "lg", "xl"]),
  variant: PropTypes.oneOf(["outline", "fill"]),
  color: PropTypes.oneOf(["gray_600_02", "white_A700", "gray_900"]),
};

export { Button };
