import React from "react";

const sizes = {
  "3xl": "text-2xl font-bold md:text-[22px]",
  "5xl": "text-4xl font-extrabold md:text-[34px] sm:text-[32px]",
  xl: "text-xl font-semibold",
  "4xl": "text-[28px] font-extrabold md:text-[26px] sm:text-2xl",
  "6xl": "text-[46px] font-extrabold md:text-[42px] sm:text-4xl",
  s: "text-base font-semibold",
  md: "text-lg font-bold",
};

const Heading = ({ children, className = "", size = "s", as, ...restProps }) => {
  const Component = as || "h6";

  return (
    <Component className={`text-gray-900 font-manrope ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Heading };
